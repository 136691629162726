var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.certificate.certificate ? _c('b-modal', {
    attrs: {
      "size": "lg",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "hide-header": "",
      "hide-footer": ""
    },
    model: {
      value: _vm.showModal,
      callback: function callback($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c('div', {
    staticClass: "modal-header"
  }, [_c('h5', {
    staticClass: "modal-title"
  }, [_vm._v(_vm._s("Renewal ".concat(_vm.certificate.certificate.name, " (").concat(_vm.certificate.vehicle.name, ")")))]), _c('button', {
    staticClass: "close",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('span', {
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("×")])])]), _c('DetailCertificate', {
    attrs: {
      "certificate": _vm.certificate
    }
  }), _c('b-row', [_c('b-col', {
    staticClass: "mt-3 mb-2",
    attrs: {
      "md": "12"
    }
  }, [_c('h4', [_vm._v("Proses Pengurusan")]), _c('hr')]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "status",
      "label": "Status Pengurusan"
    }
  }, [_c('b-form-radio', {
    staticClass: "custom-radio-color-checked",
    attrs: {
      "value": "process",
      "inline": "",
      "color": "info",
      "name": "perpanjang"
    },
    model: {
      value: _vm.form_renewal.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form_renewal, "type", $$v);
      },
      expression: "form_renewal.type"
    }
  }, [_c('h5', [_vm._v("Process")])]), _c('b-form-radio', {
    staticClass: "custom-radio-color-checked",
    attrs: {
      "value": "finish",
      "inline": "",
      "color": "success",
      "name": "perpanjang"
    },
    model: {
      value: _vm.form_renewal.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form_renewal, "type", $$v);
      },
      expression: "form_renewal.type"
    }
  }, [_c('h5', [_vm._v("Finished")])])], 1)], 1)], 1), _vm.form_renewal.type === 'finish' ? [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "certificateFile",
      "label": "File Certificate"
    }
  }, [_c('b-form-file', {
    attrs: {
      "placeholder": "Choose a file",
      "id": "customFile",
      "accept": "application/pdf"
    },
    on: {
      "change": function change($event) {
        return _vm.uploadFile($event, true);
      }
    }
  }), _vm.loading_upload_file ? _c('p', {
    staticClass: "mt-2 text-danger"
  }, [_vm._v("Please wait, uploading file is in process...")]) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "placeIssued",
      "label": "New Place Issued"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "place_issued",
      "type": "text",
      "placeholder": "Place Issued"
    },
    model: {
      value: _vm.form_renewal.placeIssued,
      callback: function callback($$v) {
        _vm.$set(_vm.form_renewal, "placeIssued", $$v);
      },
      expression: "form_renewal.placeIssued"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "processDate",
      "label": "New Date Issued"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.form_renewal.dateIssued,
      callback: function callback($$v) {
        _vm.$set(_vm.form_renewal, "dateIssued", $$v);
      },
      expression: "form_renewal.dateIssued"
    }
  }), _c('small', {
    staticStyle: {
      "float": "right"
    }
  }, [_vm._v("click "), _c('i', {
    staticClass: "fa fa-calendar"
  }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "newExpired",
      "label": "New Expired"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date"
    },
    on: {
      "change": _vm.checkFulltermInterm
    },
    model: {
      value: _vm.form_renewal.expiredDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form_renewal, "expiredDate", $$v);
      },
      expression: "form_renewal.expiredDate"
    }
  }), _c('small', {
    staticStyle: {
      "float": "right"
    }
  }, [_vm._v("click "), _c('i', {
    staticClass: "fa fa-calendar"
  }), _vm._v(" to show")])], 1)], 1)], 1)] : _vm._e(), _c('b-form-group', {
    attrs: {
      "label-for": "processDate",
      "label": "Process Date"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.form_renewal.processDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form_renewal, "processDate", $$v);
      },
      expression: "form_renewal.processDate"
    }
  }), _c('small', {
    staticStyle: {
      "float": "right"
    }
  }, [_vm._v("click "), _c('i', {
    staticClass: "fa fa-calendar"
  }), _vm._v(" to show")])], 1), _vm.isFulltermInterm ? _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "notification",
      "label": "Next Endorsement"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "md",
      "append": "Month"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "min": "1"
    },
    model: {
      value: _vm.form_renewal.nextEndorsement,
      callback: function callback($$v) {
        _vm.$set(_vm.form_renewal, "nextEndorsement", $$v);
      },
      expression: "form_renewal.nextEndorsement"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "notification",
      "label": "Notification Endorsement Before ?"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "md",
      "append": "Month"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "min": "1"
    },
    model: {
      value: _vm.form_renewal.notificationEndorsement,
      callback: function callback($$v) {
        _vm.$set(_vm.form_renewal, "notificationEndorsement", $$v);
      },
      expression: "form_renewal.notificationEndorsement"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _c('b-row', [_vm.isFulltermInterm ? _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Type of Endorsement:",
      "label-for": "Type of Endorsement"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.endorsementTypes,
      "reduce": function reduce(type) {
        return type.value;
      }
    },
    model: {
      value: _vm.form_renewal.endorsementType,
      callback: function callback($$v) {
        _vm.$set(_vm.form_renewal, "endorsementType", $$v);
      },
      expression: "form_renewal.endorsementType"
    }
  })], 1)], 1) : _vm._e(), !_vm.isCertificateInterm() && _vm.form_renewal.endorsementType === 'VERIFIED' ? _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "DateVerified",
      "label": "Date Verified"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.form_renewal.verificationDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form_renewal, "verificationDate", $$v);
      },
      expression: "form_renewal.verificationDate"
    }
  }), _c('small', {
    staticStyle: {
      "float": "right"
    }
  }, [_vm._v("click "), _c('i', {
    staticClass: "fa fa-calendar"
  }), _vm._v(" to show")])], 1)], 1) : _vm._e()], 1), _vm.form_renewal.endorsementType === 'MANUAL' ? _c('div', {
    staticClass: "mt-4"
  }, [_vm._l(_vm.form_renewal.endorsementDocuments, function (end, end_i) {
    return _c('b-row', {
      key: end_i,
      staticClass: "mt-2"
    }, [_c('b-col', {
      attrs: {
        "md": "6"
      }
    }, [_vm._v(" Endorsement #" + _vm._s(end.endorseNumber) + " ")]), _c('b-col', {
      attrs: {
        "md": "6"
      }
    }, [_c('date-picker', {
      attrs: {
        "lang": _vm.lang,
        "type": "date",
        "range": "",
        "placeholder": "Select range endorsement ".concat(end.endorseNumber)
      },
      model: {
        value: end.rangeEndorsement,
        callback: function callback($$v) {
          _vm.$set(end, "rangeEndorsement", $$v);
        },
        expression: "end.rangeEndorsement"
      }
    }), end_i !== 0 ? _c('b-button', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "ml-2",
      attrs: {
        "variant": "outline-danger",
        "title": "Delete endorsement ".concat(end.endorseNumber)
      },
      on: {
        "click": function click($event) {
          return _vm.deleteEndorsementDocument(end_i);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-times"
    })]) : _vm._e()], 1)], 1);
  }), _c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "block": "",
      "variant": "warning"
    },
    on: {
      "click": _vm.addEndorsementDocument
    }
  }, [_vm._v("Add Endorsement Based on Document")]), _c('hr')], 1)], 2) : _vm._e(), _c('b-form-group', {
    attrs: {
      "label-for": "notes",
      "label": "Notes"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "placeholder": "Type Notes"
    },
    model: {
      value: _vm.form_renewal.notes,
      callback: function callback($$v) {
        _vm.$set(_vm.form_renewal, "notes", $$v);
      },
      expression: "form_renewal.notes"
    }
  })], 1), _c('hr'), _c('b-form-group', {
    staticClass: "text-right"
  }, [_c('b-button', {
    attrs: {
      "type": "button",
      "variant": "primary",
      "disabled": _vm.loading_upload_file
    },
    on: {
      "click": function click($event) {
        return _vm.doRenewal(_vm.certificate);
      }
    }
  }, [!_vm.loading_renewal ? _c('span', [_vm._v(" Do Renewal ")]) : [_c('span', {
    staticClass: "spinner-border spinner-border-sm",
    attrs: {
      "role": "status",
      "aria-hidden": "true"
    }
  }), _vm._v(" Processing... ")]], 2), _c('b-button', {
    staticClass: "iq-bg-danger ml-3",
    attrs: {
      "type": "button",
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Cancel")])], 1)], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }