<template>
  <b-modal
    v-model="showModal"
    v-if="certificate.certificate"
    size="lg"
    no-close-on-backdrop
    no-close-on-esc
    hide-header
    hide-footer
  >
    <div class="modal-header">
      <h5 class="modal-title">{{
        `Renewal ${certificate.certificate.name} (${certificate.vehicle.name})`
      }}</h5>
      <button type="button" class="close" @click="$emit('close')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <DetailCertificate :certificate="certificate" />

    <b-row>
      <b-col md="12" class="mt-3 mb-2"> <h4>Proses Pengurusan</h4><hr /> </b-col>
      <b-col md="12">
        <b-form-group label-for="status" label="Status Pengurusan">
          <b-form-radio
            class="custom-radio-color-checked"
            v-model="form_renewal.type"
            value="process"
            inline
            color="info"
            name="perpanjang"
            ><h5>Process</h5></b-form-radio
          >
          <b-form-radio
            class="custom-radio-color-checked"
            v-model="form_renewal.type"
            value="finish"
            inline
            color="success"
            name="perpanjang"
            ><h5>Finished</h5></b-form-radio
          >
        </b-form-group>
      </b-col>
    </b-row>

    <template v-if="form_renewal.type === 'finish'">
      <b-row>
        <b-col md="6">
          <b-form-group label-for="certificateFile" label="File Certificate">
            <b-form-file
              placeholder="Choose a file"
              id="customFile"
              accept="application/pdf"
              @change="uploadFile($event, true)"
            ></b-form-file>
            <p class="mt-2 text-danger" v-if="loading_upload_file"
              >Please wait, uploading file is in process...</p
            >
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label-for="placeIssued" label="New Place Issued">
            <b-form-input
              id="place_issued"
              type="text"
              placeholder="Place Issued"
              v-model="form_renewal.placeIssued"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-form-group label-for="processDate" label="New Date Issued">
            <b-form-input
              type="date"
              v-model="form_renewal.dateIssued"
            ></b-form-input>
            <small style="float: right"
              >click
              <i class="fa fa-calendar"></i>
              to show</small
            >
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label-for="newExpired" label="New Expired">
            <b-form-input
              type="date"
              v-model="form_renewal.expiredDate"
              @change="checkFulltermInterm"
            ></b-form-input>
            <small style="float: right"
              >click
              <i class="fa fa-calendar"></i>
              to show</small
            >
          </b-form-group>
        </b-col>
      </b-row>
    </template>

    
    <b-form-group label-for="processDate" label="Process Date">
      <b-form-input
        type="date"
        v-model="form_renewal.processDate"
      ></b-form-input>
      <small style="float: right"
        >click
        <i class="fa fa-calendar"></i>
        to show</small
      >
    </b-form-group>

    <b-row v-if="isFulltermInterm">
      <b-col md="6">
        <b-form-group
          label-for="notification"
          label="Next Endorsement"
        >
          <b-input-group size="md" append="Month">
            <b-form-input
              type="number"
              min="1"
              v-model="form_renewal.nextEndorsement"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label-for="notification"
          label="Notification Endorsement Before ?"
        >
          <b-input-group size="md" append="Month">
            <b-form-input
              type="number"
              min="1"
              v-model="form_renewal.notificationEndorsement"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="6"
        v-if="isFulltermInterm"
      >
        <b-form-group
          label="Type of Endorsement:"
          label-for="Type of Endorsement"
        >
          <v-select
            label="text"
            :options="endorsementTypes"
            :reduce="(type) => type.value"
            v-model="form_renewal.endorsementType"
          ></v-select>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        v-if="
          !isCertificateInterm()
          && form_renewal.endorsementType === 'VERIFIED'
        "
      >
        <b-form-group
          label-for="DateVerified"
          label="Date Verified"
        >
          <b-form-input
            type="date"
            v-model="form_renewal.verificationDate"
          ></b-form-input>
          <small style="float: right"
            >click <i class="fa fa-calendar"></i> to show</small
          >
        </b-form-group>
      </b-col>
    </b-row>
    <div
      v-if="form_renewal.endorsementType === 'MANUAL'"
      class="mt-4"
    >
      <b-row
        v-for="(end, end_i) in form_renewal
          .endorsementDocuments"
        :key="end_i"
        class="mt-2"
      >
        <b-col md="6"> Endorsement #{{ end.endorseNumber }} </b-col>
        <b-col md="6">
          <date-picker
            v-model="end.rangeEndorsement"
            :lang="lang"
            type="date"
            range
            :placeholder="`Select range endorsement ${end.endorseNumber}`"
          ></date-picker>
          <b-button
            variant="outline-danger"
            class="ml-2"
            v-if="end_i !== 0"
            v-b-tooltip.hover
            :title="`Delete endorsement ${end.endorseNumber}`"
            @click="deleteEndorsementDocument(end_i)"
          >
            <i class="fa fa-times"></i>
          </b-button>
        </b-col>
      </b-row>
      <b-col md="12" class="mt-4">
        <b-button
          block
          variant="warning"
          @click="addEndorsementDocument"
          >Add Endorsement Based on Document</b-button
        >
        <hr>
      </b-col>
    </div>
      
    <b-form-group label-for="notes" label="Notes">
      <b-form-textarea
        placeholder="Type Notes"
        v-model="form_renewal.notes"
      ></b-form-textarea>
    </b-form-group>

    <hr />
    <b-form-group class="text-right">
      <b-button type="button" variant="primary"
        :disabled="loading_upload_file"
        @click="doRenewal(certificate)">
        <span v-if="!loading_renewal"> Do Renewal </span>
        <template v-else>
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Processing...
        </template>
      </b-button>
      <b-button
        type="button"
        variant="danger"
        class="iq-bg-danger ml-3"
        @click="$emit('close')"
        >Cancel</b-button
      >
    </b-form-group>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import { certificatesActions, filesActions } from '@src/Utils/helper'

export default {
  name: 'ModalRenewal',
  components: {
    DetailCertificate: () =>
      import('@components/certificate/DetailCertificate'),
    EndorsementCertificate: () =>
      import('@components/certificate/EndorsementCertificate'),
  },
  computed: {
    ...mapState('certificates', {
      modal_renewal: (state) => state.modal_renewal,
    }),
    showModal() {
      return this.modal_renewal.show
    },
    certificate() {
      return this.modal_renewal.certificate
    }
  },
  watch: {
    showModal() {
      if (this.showModal) {
        this.onMounted()
      }
    }
  },
  data() {
    return {
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
      loading_upload_file: false,
      loading_renewal: false,
      endorsementTypes: [
        { value: 'ISSUED', text: 'Trigger Endorsement Based on Date Issued' },
        {
          value: 'VERIFIED',
          text: 'Trigger Endorsement Based on Date Verified',
        },
        { value: 'MANUAL', text: 'Trigger Endorsement Based on Document' },
      ],
      form_renewal: {
        type: 'process',
        notes: '',
        file: '',
        placeIssued: '',
        dateIssued: '',
        expiredDate: '',
        processDate: '',
        verificationDate: '',
        endorsementType: 'ISSUED',
        nextEndorsement: '',
        notificationEndorsement: '',
        endorsementDocuments: [
          {
            endorseNumber: 1,
            rangeEndorsement: '',
          },
        ],
      },
      isFulltermInterm: false,
    }
  },
  methods: {
    ...certificatesActions,
    ...filesActions,
    async uploadFile(event) {
      this.loading_upload_file = true

      const files = [...event.target.files]
      let file = files[0]

      let formData = new FormData()
      formData.append('file', file)

      let res = await this.upload(formData)
      if (res.status == 'success') {
        this.loading_upload_file = false
        this.form_renewal.file = res.data.fileName
      } else {
        this.loading_upload_file = false
        if (res.data.message != null)
          this.$swal('Error', res.data.message, 'error')
        else
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
      }
    },
    async doRenewal(certificate) {
      this.loading_renewal = true

      let dateIssued = this.$options.filters.parseDate(
        this.form_renewal.dateIssued,
        'DD-MM-YYYY'
      )
      let expiredDate = this.$options.filters.parseDate(
        this.form_renewal.expiredDate,
        'DD-MM-YYYY'
      )
      let processDate = this.$options.filters.parseDate(
        this.form_renewal.processDate,
        'DD-MM-YYYY'
      )
      let verificationDate = this.$options.filters.parseDate(
        this.form_renewal.verificationDate,
        'DD-MM-YYYY'
      )

      let payload = {
        notes: this.form_renewal.notes,
        processDate,
      }

      if (this.form_renewal.type == 'finish' && certificate.certificate.certificateType === 'SHORT_TERM') {
        payload = {
          notes: this.form_renewal.notes,
          file: this.form_renewal.file,
          placeIssued: this.form_renewal.placeIssued,
          dateIssued,
          expiredDate,
          processDate
        }
      }

      if (this.form_renewal.type == 'finish' && certificate.certificate.certificateType === 'FULL_TERM') {
        if (!this.isCertificateInterm()) {
          let endorsements = [];
          if (this.form_renewal.endorsementType === 'ISSUED') {
            // ENDORSEMENT BASED ON DATE ISSUED
            const res_end = await this.getEndorsementDates({
              type: 'FULL_TERM',
              params: {
                dateParam: dateIssued,
                expiredDate: expiredDate,
                nextEndorsement: this.form_renewal.nextEndorsement
              }
            })
            if (!res_end) {
              this.$swal(
                'Error while getting endorsement!',
                'Please try again later or contact the admin.',
                'error'
              )

              return
            }
            endorsements = res_end.data
          } else if (this.form_renewal.endorsementType === 'VERIFIED') {
            // ENDORSEMENT BASED ON DATE VEFIRIED
            const res_end = await this.getEndorsementDates({
              type: 'FULL_TERM',
              params: {
                dateParam: verificationDate,
                expiredDate: expiredDate,
                nextEndorsement: this.form_renewal.nextEndorsement
              }
            })

            if (!res_end) {
              this.$swal(
                'Error while getting endorsement!',
                'Please try again later or contact the admin.',
                'error'
              )

              return
            }
            endorsements = res_end.data
          } else {
            // ENDORSEMENT BASED ON DOCUMENT
            endorsements = this.getEndorsementDocument()
          }

          payload = {
            notes: this.form_renewal.notes,
            file: this.form_renewal.file,
            placeIssued: this.form_renewal.placeIssued,
            verificationDate,
            endorsementType: this.form_renewal.endorsementType,
            dateIssued,
            expiredDate,
            processDate,
            nextEndorsement: this.form_renewal.nextEndorsement,
            notificationEndorsement: this.form_renewal.notificationEndorsement,
            endorsement: endorsements,
          }
        } else {
          payload = {
            notes: this.form_renewal.notes,
            file: this.form_renewal.file,
            placeIssued: this.form_renewal.placeIssued,
            dateIssued,
            expiredDate,
            processDate,
          }
        }
      }

      let form_has_empty_value = false
      for (var key in payload) {
        if (['verificationDate'].includes(key)) {
          continue
        } else {
          if (payload[key] === '') form_has_empty_value = true
          else if (Array.isArray(payload[key]) && !payload[key].length)
            form_has_empty_value = true
        }
      }

      if (form_has_empty_value || Object.keys(payload).length == 0)
        this.$swal(
          'Empty form!',
          'Please check again and fill all the forms.',
          'error'
        )
      else {
        let res
        if (this.form_renewal.type == 'finish') {
          res = await this.finishRenewalCertificateVehicle({
            id: certificate.id,
            data: payload,
          })
        } else {
          res = await this.processRenewalCertificateVehicle({
            id: certificate.id,
            data: payload,
          })
        }
        if (res.status == 'success') {
          this.resetForm()
          this.$emit('refresh')
          this.$swal(
            `Renewal Process Success!`,
            `Certificate ${certificate.certificate.name} ${
              this.form_renewal.type == 'finish' ? 'finished' : 'extended'
            } successfully.`,
            'success'
          )
          this.$emit('close')
        } else {
          if (res.data.message != null)
            this.$swal('Error', res.data.message, 'error')
          else
            this.$swal(
              'Error',
              'Something wrong with the server. Please try again later.',
              'error'
            )
        }
        this.loading_renewal = false
      }
    },
    onMounted() {
      this.resetForm();
      this.form_renewal.nextEndorsement = this.certificate.nextEndorsement;
      this.form_renewal.notificationEndorsement = this.certificate.notificationEndorsement;
    },
    certificateRangeInMonth() {
      return this.$options.filters.getDateDifferenceInMonth(
        this.form_renewal.expiredDate,
        this.form_renewal.dateIssued
      )
    },
    isCertificateInterm() {
      const months = this.certificateRangeInMonth()

      if (months && months <= 12) return true
      return false
    },
    checkFulltermInterm() {
      this.isFulltermInterm = this.certificate.certificate.certificateType === 'FULL_TERM'
      && this.certificateRangeInMonth() > 0
      && !this.isCertificateInterm()
    },
    addEndorsementDocument() {
      const endorsementDocuments = this.form_renewal.endorsementDocuments

      endorsementDocuments.push({
        endorseNumber: endorsementDocuments.length + 1,
        rangeEndorsement: '',
      })
    },
    deleteEndorsementDocument(index) {
      this.form_renewal.endorsementDocuments.splice(index, 1)
    },
    getEndorsementDocument() {
      let endorsementDocuments = []
      this.form_renewal.endorsementDocuments.forEach((endorsement) => {
        endorsementDocuments.push({
          endorseNumber: endorsement.endorseNumber,
          startEndorseDate: this.$options.filters.parseDate(
            new Date(endorsement.rangeEndorsement[0])
              .toISOString()
              .substring(0, 10),
            'DD-MM-YYYY'
          ),
          endEndorseDate: this.$options.filters.parseDate(
            new Date(endorsement.rangeEndorsement[1])
              .toISOString()
              .substring(0, 10),
            'DD-MM-YYYY'
          ),
        })
      })

      return endorsementDocuments
    },
    resetForm() {
      this.form_renewal = {
        type: 'process',
        notes: '',
        file: '',
        placeIssued: '',
        dateIssued: '',
        expiredDate: '',
        processDate: '',
        verificationDate: '',
        endorsementType: 'ISSUED',
        nextEndorsement: '',
        notificationEndorsement: '',
        endorsementDocuments: [
          {
            endorseNumber: 1,
            rangeEndorsement: '',
          },
        ],
      }
      this.isFulltermInterm = false
    }
  },
}
</script>
